<template>
  <div id="container" class="day-mode">
    <aside>
      <div class="link-box">
        <div class="avatar-box">
          <img src="../../assets/avatar.jpg" alt="avatar" class="avatar" />
          <p>Apollo</p>
        </div>
        <div
          class="route-link"
          :class="{ 'active-black': $route.name === 'home' }"
        >
          <router-link to="/home"></router-link>
        </div>
        <div
          class="route-link"
          :class="{ 'active-black': $route.name === 'tools' }"
        >
          <router-link to="/tools"></router-link>
        </div>
        <div
          class="route-link"
          :class="{ 'active-black': $route.name === 'wzkAdmin' }"
        >
          <router-link to="/wzkAdmin"></router-link>
        </div>
        <div
          class="route-link"
          :class="{ 'active-black': $route.name === 'app' }"
        >
          <router-link to="/app"></router-link>
        </div>
        <div class="logo-box">
          <p>Dark Mode</p>
          <input type="checkbox" />
        </div>
      </div>
    </aside>
    <main>
      <transition name="fade-right" mode="out-in">
        <router-view></router-view>
      </transition>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
#container {
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 20px;
}

aside {
  width: 100px;
  background-color: #f4ebe4;
  border-radius: 13px;

  .link-box {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 13px;

    .avatar-box,
    .logo-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      box-sizing: border-box;
      flex: 2;
    }

    .route-link {
      background-color: #fff;
      border-radius: 100px;
      min-width: 50px;
      min-height: 50px;
      margin: 10px 0;
      transition: background-color 0.5s ease;

      a {
        display: block;
        height: 100%;
        width: 100%;
      }
    }

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}

.active-black {
  background-color: #000 !important;
}

main {
  flex: 1;
  padding-left: 20px;
  transition: background-color 0.5s;
  box-sizing: border-box;
}
</style>
